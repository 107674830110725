import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

const baseStyles = {
	top: '80px',
	position: 'fixed',
	display: 'block',
	w: 'full',
	h: 'full',
	backgroundColor: 'rgba(0,0,0,0.6)',
	backgroundAttachment: 'fixed',
	left: 0,
	opacity: 0,
	visibility: 'hidden',
	zIndex: 1,
	transition: 'opacity 300ms ease-in-out 0ms',
}

const activeStyles = { opacity: 1, visibility: 'visible' }

const ContentCover = memo(({ style, onClick, isActive }) => {
	/** Return dark cover */
	const styles = isActive ? { ...baseStyles, ...activeStyles } : baseStyles

	return <Box sx={styles} onClick={onClick} />
})

ContentCover.propTypes = {
	/** styles passed to the content cover */
	style: PropTypes.object,
	/** OnClickHandler Method */
	onClick: PropTypes.func.isRequired,
	/** Is it active */
	isActive: PropTypes.bool.isRequired,
}

ContentCover.defaultProps = {
	isActive: false,
}

export default ContentCover
